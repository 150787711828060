<template>
  <CButton @click="bundle.action" class="bundle-button w-100" align="space">
    <span class="bundle-button__font bundle-button__font--white">
      {{ bundle.leftText }}
    </span>

    <span class="d-inline-flex">
      <span class="bundle-button__font bundle-button__font--before">
        {{ bundle.beforePrice }}&nbsp;&nbsp;&nbsp;
      </span>
      <span class="bundle-button__font bundle-button__font--after">
        {{ bundle.afterPricePreText }}&nbsp;
      </span>
      <span
        class="bundle-button__font bundle-button__font--after bundle-button__font--underline"
      >
        {{ bundle.afterPrice }}
      </span>
    </span>
  </CButton>
</template>

<script>
import CButton from "@/features/ui/СButton.vue";
import {currencyFormat, declension} from "@/tools/helpers";
export default {
  name: "BundleButton",
  components: {
    CButton,
  },
  props: {
    bundleData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bundle() {
      return this.createButton(this.bundleData)
    },
  },
  methods: {
    declension,
    currencyFormat,
    declensionMonth(month) {
      return this.declension(month, ["month", "month", "month"]);
    },
    createButton(bundle) {
      return {
        leftText: this.$t("general.settingsPages.bundles.button.leftText", {
          amount: bundle.months || 0,
          month: this.declensionMonth(bundle.months || 0),
          discount: bundle.discount,
        }),
        beforePrice: this.currencyFormat(bundle?.face_old_price || 0),
        afterPricePreText: this.$t(
          "general.settingsPages.bundles.button.afterPricePreText"
        ),
        afterPrice: `$${bundle?.face_price || 0}`,
        id: bundle.id,
        action: () =>  bundle.action(bundle)
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.bundle-button {
  &__font {
    &--before {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 9px;
      line-height: 133%;
      text-decoration: line-through;
      text-align: right;
      color: rgba($white, 0.46);
    }

    &--after {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      text-align: right;
      color: $white;
    }

    &--underline {
      text-decoration: underline;
    }
  }
}
</style>
