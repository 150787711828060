<template>
  <b-row class="settings">
    <b-col>
      <PageHeader :title="$t('general.settingsPages.bundles.title')" />

      <b-row>
        <b-col>
          <div class="settings__list">
            <div
              class="settings__item mt-3"
              v-for="bundle in bundleList"
              :key="bundle.id"
            >
              <div class="settings__bundle-wrapper">
                <BundleButton :bundle-data="bundle" class="settings__button settings__button--bundle"/>
              </div>

              <div class="settings__button settings__button--delete">
                <button @click="removeBundle(bundle)">
                  <CIcon
                    name="close-button"
                    class="settings__icon settings__icon--delete"
                  />
                </button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <button @click="newBundle" class="subscribed-button mt-3" v-if="true">
            <span class="subscribed-button__font">
              {{
                $t("general.settingsPages.bundles.button.addNewButton")
              }}</span
            >
          </button>
        </b-col>

        <div class="settings__button-wrapper">
          <button
            type="button"
            @click.prevent="update"
            class="base-button settings__button settings__button--right"
          >
            <span class="base-button__font">
              {{ $t("general.settingsPages.bundles.button.save") }}
            </span>
          </button>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import PageHeader from "@/layout/PageHeader.vue";
// import CIcon from "@/features/ui/CIcon.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import CIcon from "@/features/ui/CIcon.vue";
import { mapActions, mapGetters } from "vuex";
import { currencyFormat } from "@/tools/helpers";
import BundleButton from "@/features/components/BundleButton.vue";
export default {
  name: "PageSettingsBundles",
  components: {
    BundleButton,
    CIcon,
    PageHeader,
  },

  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({ user: "user" }),
    bundleList() {
      return this.user?.discountBundles;
    },
  },

  created() {
    this.price = this.user?.face_price || "0";
  },

  data() {
    return {
      price: this.user?.face_price || "0",
    };
  },

  methods: {
    currencyFormat,
    ...mapActions({
      createPrice: "price/createPrice",
      deleteBundle: "price/deleteBundle",
    }),

    newBundle() {
      this.$popup.open("CreateBundlePopup");
    },

    removeBundle(bundle) {
      console.log("delete + ", bundle);
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.deleteBundle({ bundleId: bundle.id }).catch(
          this.checkErrors
        );
      });
    },

    async update() {
      const isValid = await this.$validator.validateAll();

      if (!isValid || this.requestInProgress) return;

      return this.waitRequest(() => {
        const price = this.price?.replace("$", "")?.replaceAll(",", "") ?? "0";
        return this.createPrice({ price }).catch(this.checkErrors);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &__form {
    margin-top: em(32);
    width: 100%;
    margin-right: em(16);
    margin-left: em(16);
  }

  &__list {
    display: block;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    margin-left: auto;

    &--delete {
      margin-left: 6px;
    }

    &--right {
      margin-top: em(17);
      margin-right: em(15);
    }

    &--bundle {
      pointer-events: none;
    }
  }

  &__menu-button {
    width: 100%;
  }

  &__bundle-wrapper {
    flex: 1;
  }

  &__button-wrapper {
    display: flex;
    width: 100%;
    margin-top: em(19);
    border-top: 1px solid $app-gray-2;
  }

  &__input {
    margin-top: em(16);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: em(0);
  }

  &__field {
  }

  &__group-title {
    padding: em(21) 0 em(21) em(20);
  }

  &__font {
    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
    }

    &--group-title {
      font-family: $font-default;
      font-style: normal;
      line-height: normal;
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--delete {
      color: $black;
      width: em(24);
      height: em(24);
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}

.subscribed-button {
  display: flex;
  justify-content: center;
  padding: em(14) em(16);
  border: 1.5px solid $app-gray-25;
  border-radius: 100px;

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #308dff;
    text-transform: uppercase;

    &--white {
      color: $white;
    }
  }
}

</style>
